import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimeFormat',
  pure: false,
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    const dateMoment = moment(value);
    if (!dateMoment.isValid()) {
      return '';
    }

    if (args[0]) {
      return dateMoment.format(args[0]);
    } else {
      if (dateMoment.diff(moment(), 'hours') >= -12) {
        return dateMoment.fromNow();
      }

      if (dateMoment.diff(moment(), 'days') >= -5) {
        return dateMoment.format('ddd HH:mm');
      }

      if (dateMoment.get('year') !== moment().get('year')) {
        return dateMoment.format('MMM D YY, HH:mm');
      }

      return dateMoment.format('MMM D, HH:mm');
    }
  }
}
