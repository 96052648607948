import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DateTimeFormatPipe } from './date-time-format.pipe';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [DateTimeFormatPipe],
  exports: [DateTimeFormatPipe],
})
export class PipesModule {}
